/*modal css*/
.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.8);
}

.modal-container {
    max-width: 550px;
    width: 100%;
    height: auto;
    background: #fff;
    margin: auto;
    overflow: auto;
    pointer-events: all;
    padding: 10px;
    position: relative;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    max-height: 100vh;
}

.signin-modal {
    max-width: 370px;
    padding: 20px 15px;
}

.contest-modal {
    padding: 20px;
    max-width: 650px;
}

.btn-close{
    position: absolute;
    top: 8px;
    right: 12px;
}

/* side modal */
.side-modal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, .7);
}

.side-modal-container {
    top: 0;
    left: 0;
    height: 100%;
    -webkit-animation: slideInLeft .3s;
    animation: slideInLeft .3s;
    background: #fff;
    overflow: auto;
    pointer-events: all;
    width: 275px;
    max-height: 100vh;
}

@keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInLeft {
    animation-name: slideInLeft;
}

.side-account {
    height: 175px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    padding: 10px;
    background: #e94403;
}

.side-modal-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.side-modal-list li {
    padding: 10px 15px;
}

.side-modal-list li a, .side-modal-list li button{
    font-size: 18px !important;
} 