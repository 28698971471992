@font-face {
    font-family: 'gothic';
    src: url('../fonts/gothic.eot');
    src: local('gothic'), url('../fonts/gothic.woff') format('woff'),
    url('../fonts/gothic.ttf') format('truetype');
}

body {
    font-family: 'gothic';
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
}

.poppins {
    font-family: 'Poppins', sans-serif;
}

/* text stlyes */
.f-700 {
    font-weight: 700;
}

.f-900 {
    font-weight: 900;
}

.f-500 {
    font-weight: 500;
}

.f-600 {
    font-weight: 600;
}

.f-10 {
    font-size: 10px;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.f-18 {
    font-size: 18px;
}

a {
    color: #212529;
}

a:hover {
    color: #212529;
}

.header-list li a,
.header-list li button {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
}

.header-list li a:hover,
.header-list li button:hover {
    color: #e94403;
}

/* text styles ends here */
.errdiv {
    background: #e87c03;
    color: #fff;
    font-size: 12px;
}

.err-border {
    border: 2px solid red;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn-theme {
    background: #e94403;
    color: #fff !important;
    font-size: 14px;
}

.theme-red {
    color: #e94403 !important;
}

.text-underline {
    text-decoration: underline;
}

.logo {
    max-height: 55px;
    object-fit: scale-down;
}

.relative {
    position: relative;
}

hr[data-title] {
    margin: 1.78571429em 0;
    text-align: center;
}

hr[data-title]:before {
    content: attr(data-title);
    background: #fff;
    position: relative;
    bottom: 14px;
    font-size: 14px;
    padding: 0.92857143em;
    font-family: 'gothic';
}

.bg--facebook {
    background: #3b5998;
    color: #fff;
}

.bg--googleplus {
    background: #dd4b39;
    color: #fff;
}

/* header css */
.header {
    padding: 0 30px;
    font-size: 15px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-height {
    min-height: 60px;
}

.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-list li {
    display: inline-block;
    padding: 0 15px;
    font-weight: 500;
    font-size: 14px;
}

.menu-list li a {
    text-decoration: none;
}

/* header css ends here*/

/* landing page css */
.banner {
    height: calc(100vh - 60px);
    /* will change */
    background-image: url("../img/01.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.banner-video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    height: calc(100vh - 60px);
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
}

.banner-video-container > video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

@media screen and (max-aspect-ratio: 1920/1080) {
    .banner-video-container > video {
        height: 100%;
    }
}

@media screen and (min-aspect-ratio: 1920/1080) {
    .banner-video-container > video {
        width: 100%;
    }
}

.banner-video {
    width: 100%;
    height: auto;
}

.banner-tint {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    width: 100%;
    height: 100%;
}

.banner-text {
    width: 100%;
    max-width: 1024px;
}

.banner-text h1 {
    font-size: 3.2rem;
}

.tab-header {
    height: 55px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background: #f5f5f5;
}

.btn-tab {
    height: 100%;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 0;
    font-weight: 600;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    word-wrap: break-word;
}

.tab-header .active,
.profile-tabs .active {
    border-bottom: 2px solid #e94403;
}

.post {
    font-size: 13px;
    margin-bottom: 30px;
}

.post-section {
    padding: 45px 0;
}

.post-img-holder {
    background: #ececec;
    padding: 6px;
    /* border-radius: 5px; */
}

.bg-light {
    background: #ececec !important;
}

.post-img {
    height: 250px;
    background: #ececec;
}

.post-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: zoom-in;
}

.post-info {
    position: absolute;
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    top: 12px;
    left: 12px;
    display: none;
    max-width: 50%;
    flex-wrap: wrap;
}

.post-img-holder:hover .post-info {
    display: flex;
}

.post-info span {
    padding: 0px 8px;
    /* border-radius: 20px; */
    background: rgba(0, 0, 0);
    margin-right: 6px;
    margin-bottom: 4px;
    border-radius: 8px;
}

/* footer css */
.footer {
    background: #272727;
    font-size: 15px;
    color: #ececec !important;
}

.footer a {
    color: #ececec !important;
}

/* fotter css ends here */
.profile-card {
    background: #fff;
    position: relative;
}

.profile-image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.profile-bg {
    height: 120px;
    width: 100%;
}

.profile-image {
    height: 125px;
    width: 125px;
    z-index: 1;
    border-radius: 50%;
    background: #fff;
    position: relative;
}

.profile-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.profile-img-edit {
    position: absolute !important;
    top: 0 !important;
    right: 20px !important;
    cursor: pointer;
}

.profile-details {
    padding: 0 15px 15px 15px;
    word-wrap: break-word;
    margin-top: -65px;
}

.profile-tabs {
    min-height: 40px;
    display: flex;
    align-items: stretch;
}

.profile-tabs .btn-tab {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 30px;
    font-weight: 500;
    font-size: 15px;
}

.btn-publish {
    position: absolute;
    bottom: 25px;
    right: 25px;
    min-width: 122px;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
}

.btn-pill {
    border-radius: 20px;
    min-width: 120px;
    font-size: 15px;
}

.btn-a {
    padding: 0;
    margin: 0;
    box-shadow: none;
    background: transparent;
    border: 1px solid transparent;
    -webkit-user-select: none;
            user-select: none;
    outline: none !important;
}

.image-upload-section {
    border: 2px dashed #cecece;
    height: 250px;
    color: #cecece;
}

.image-upload-section i {
    font-size: 40px;
    margin-bottom: 10px;
}

.img-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-upload-section p {
    color: #808080;
}

.guidelines li {
    font-size: small;
    margin-bottom: 2px;
}

.coll-counter h1 {
    font-weight: 700;
    font-size: 3.5em;
    color: #e94403;
}

.coll-counter h2 {
    font-size: 3em;
    font-weight: 700;
    color: #e94403;
}

.coll-counter h3 {
    font-weight: 700;
    color: #e94403;
}

.coll-border {
    border-right: 1px dashed #dee2e6;
}

.coll-counter-item {
    flex: 1 1 50%;
    padding: 10px 0;
}

.coll-counter-item p {
    margin: 0;
}

.cat-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.cat-list li {
    display: inline-block;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cat-active {
    background: #e94403;
    color: #fff;
    border: 1px solid #e94403;
}

.static-content-header {
    min-height: 428px;
    color: #fff;
    margin-bottom: 35px;
    text-align: center;
    position: relative;
}

.zindex-2 {
    z-index: 2;
}

.static-content-header h1 {
    font-weight: 700;
    font-size: 54px;
    word-wrap: break-word;
}

.static-content {
    margin-bottom: 40px;
}

.static-content h5 {
    font-weight: 700;
}

.static-content h2 {
    margin-bottom: 20px;
}

.static-content p {
    text-align: justify;
}

.static-content a {
    color: #e94403;
}

.pay-prev-img {
    height: 200px;
    margin-bottom: 15px;
    background: #cecece;
}

.pay-prev-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pointer {
    cursor: pointer;
}

.list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upgradeUl .list-active {
    color: #e94403;
}

.color-green {
    color: green !important;
}

.bg-light-grey {
    background: #f5f5f5;
}

.approv-pill {
    display: inline-block;
    padding: 2px 15px;
    color: #fff !important;
    border-radius: 15px;
}

.pendibg-bg {
    background: #e94403;
}

.modalprev-img {
    max-height: 85vh;
    max-width: 100vw;
}

.mb-70 {
    margin-bottom: 70px;
}

.f-20 {
    font-size: 20px;
}

.btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50%;
    z-index: 21;
}

.announcement {
    min-height: 165px;
}

.announce-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.h100p {
    height: 100%;
}

.select-round {
    background: #ececec;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    padding: 0;
    border: 1px solid #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.select-round-active {
    background: #e94403;
    border: 1px solid #e94403;
}

.select-round-active span {
    display: inline-block;
    font-size: 10px;
    color: #fff;
    text-align: center;
}

.sourcesans {
    font-family: 'Source Sans Pro', sans-serif;
}

.btn-selected {
    position: fixed;
    bottom: 5px;
    /* top: 60px; */
    right: 10px;
    /* right: 0; */
    padding: 10px 25px;
    font-size: 16px;
    border-radius: 0;
    z-index: 10;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.multi-select-div {
    flex: 0 0 180px;
    margin: 5px 10px;
}

.multi-select-img {
    height: 120px;
    background: #cecece;
    position: relative;
}

.multi-select-img div {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.multi-select-img:hover div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.multi-select-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.color-white {
    color: #fff !important;
}

.sponsor-img {
    height: 60px;
    padding: 0 5px;
    margin-bottom: 10px;
}

.sponsor-img img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.slides {
    flex-flow: row nowrap;
    white-space: nowrap;
    overflow: hidden;
}

@-webkit-keyframes slideInLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes slideInLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.slideInLeft {
    -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
}

.gradient_line {
    margin: 0 0 50px 0;
    display: block;
    border: none;
    height: 1px;
    background: #0071b9;
    background: linear-gradient(
            to right,
            white,
            #0071b9,
            #26abff,
            #0071b9,
            white
    );
}

/* //acordion */
.accordion {
    background-color: #eee;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
}

/* .acc-active,
.accordion:hover {
    background-color: #ccc;
} */

.accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.acc-active:after {
    content: '\2212';
}

.acc-panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.accordion:focus {
    outline: none;
}

.prize-img {
    max-height: 200px;
    object-fit: contain;
}

.header-img {
    width: 100%;
    height: auto;
    margin-bottom: 36px;
}

.btn-light-grey {
    background-color: #e2e6ea;
    border-color: #e2e6ea;
}

label {
    display: inline-block;
    margin-bottom: 0.2rem;
    font-weight: 600;
    color: rgba(33, 37, 41, 0.7);
}

.err-msg {
    position: absolute;
    bottom: -18px;
    left: 0;
}

.update-form .form-group {
    width: 50%;
    float: left;
}

.update-form .form-group:nth-child(odd) {
    padding-right: 5px;
}

.update-form .form-group:nth-child(even) {
    padding-left: 5px;
}

.update-form .form-group:nth-child(7) {
    width: 100% !important;
}

.update-form .form-group:nth-child(8) {
    padding-right: 5px;
}

.update-form .form-group:nth-child(9) {
    padding-left: 5px;
}

.show-header {
    display: none;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.annoucelist li {
    padding: 10px 0;
}

/* responsive */
@media only screen and (max-width: 841px) {
    .hide-header {
        display: none !important;
    }

    .show-header {
        display: block !important;
    }

    .header-height div {
        flex: 1 1;
    }

    .logo-holder {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .banner-text h1 {
        font-size: 2.5rem;
    }

    .static-content-header h1 {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 761px) {
    .multi-select-img {
        height: 200px;
    }
}

.w-50p {
    width: 50%;
    float: left;
}

.category-img {
    max-width: 100%;
    height: auto;
    text-align: center;
    justify-content: center;
}

.category-title {
    margin-top: 215px;
}

.category-head {
    color: #089cda;
    font-weight: 700;
}

.category-content {
    max-width: 350px;
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
}

.category-btn {
    background: rgba(4, 168, 232, 0.7) !important;
    border-color: rgba(4, 168, 232, 0.3) !important;
    color: #fff !important;
}

.float-whatsapp {
    display: block;
    position: fixed;
    bottom: 65px;
    right: 25px;
    box-shadow: 0 3px 6px rgba(0, 255, 0, 0.16), 0 3px 6px rgba(0, 255, 0, 0.23);
    height: 55px;
    width: 55px;
    border-radius: 50%;
}

.float-whatsapp img {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 576px) {
    .btn-category {
        flex-basis: 45%;
    }
}

._show_1e.wrap_mW,
.label_39 {
    z-index: 9 !important;
}

.countdown {
    font-size: 3.5rem;
    font-weight: 500;
}

.countdown + div {
    font-size: 14px;
}

.countdown-divider {
    font-size: 2.2rem;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 22px;
}



.show-counter {
    padding: 0.5rem;
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
  }
  
  .show-counter .countdown {
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    margin-top: 1rem;
  }